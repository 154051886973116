import React from "react";
import Layout from "src/components/layout/layout";
import { ProjectsGrid } from "src/components/projectsGrid/projectsGrid";
import { ProjectBanner } from "src/components/banner";
import { HelmetComponent } from "components/helmet";

export const Projects = ({
  data: {
    allProject: { edges: data },
  },
}) => {
  return (
    <Layout key="projects">
      <HelmetComponent />
      <ProjectBanner />
      <ProjectsGrid isProjectPage data={data.map(({ node }) => ({ ...node }))} />
    </Layout>
  );
};
