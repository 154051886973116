import React from "react";
import { graphql } from "gatsby";
import { Projects as ProjectsLayout } from "src/components/projects/projects";

const Projects = (props) => {
  return <ProjectsLayout {...props} />;
};

export default Projects;

export const query = graphql`
  query AllProjects {
    allProject {
      totalCount
      edges {
        node {
          title
          subTitle
          imageName
          slug
          externalLink
          folder
          contents
        }
      }
    }
  }
`;
